.cardCotacao {
    max-width: 350px;
    margin: 0.5vw;
    border-radius: 20px;
    min-height: 430px;
    max-height: 66vh;
}

.cardCotacaoBody {
    background-color: #ffffff;
    padding: 1rem;
    padding-top: 0;
    font-family: 'Montserrat', sans-serif;
}

.cardCotacaoFooter {
    background-color: #ffffff;
    padding: 0rem 1.25rem;
    border-top: none
}

.cardCotacaoDescicaoCobertura {
    text-align: center;
    font-size: 16px;
    font-weight: 700;
    padding: 0.50rem;
    padding-top: 0;
    padding-bottom: 0;
}

.cardCotacaoValorCobertura {
    text-align: center;
    font-size: 20px;
    font-weight: 700;
    padding-top: 0;
    margin-bottom: 0;
}

.cardCotacaoValorCoberturaLabel {
    text-align: center;
    font-size: 11px;
    margin-top: 0;
    padding-top: 0;
    padding-bottom: 0;
}

.cardCotacaoDetalheCobertura {
    display: flex;
    font-size: 14px;
    padding-top: 0;
}

.cardCotacaoDetalheCoberturaIcon {
    margin: 2px;
    font-size: 18px;
    color: #fe6633;
}