.customNodePadraoBody {
    background-color: #EEF0F3;
    width: 150px;
    height: 150px;
    text-align: center;
  }
  .customNodePadraoIcone {
    margin-top: 20px;
  }
  
  .customNodePadraoText {
    margin-top: 5px;
    font-size: large;
    font-weight: 800;
  }
  
  .customNodePadraoTitle {
    font-size: medium;
    font-weight: bold;
  }
  