.PageAgenda_ContainerCardAgendaItem {
  background: #fafafa;
  border: 1px solid #cccccc;
  padding: 10px;
  border-radius: 5px;
  margin-bottom: 20px;
}

.PageAgenda_ContainerCardAgendaBackgroundRed {
  background-color: #ffcdd2;
}

.PageAgenda_ContainerCardAgendaBackgroundYellow {
  background-color: #fff9c4;
}

.PageAgenda_ContainerCardAgendaItemFace {
  background: #fff;
  border-radius: 4px;
  border: 1px solid #707070;
  float: right;
}

.PageAgenda_ContainerCardAgendaCheckBox {
  margin-top: -0.5rem !important;
}
