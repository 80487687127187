.relatorioModuloV2Body {
  font-family: "Inter", Arial, Helvetica, sans-serif;
  font-size: 14px;
  margin: 0;
  color: #3a3a3a;
}

.relatorioModuloV2Logo {
  text-align: center;
  padding: 20px 0;
  border-bottom: 1pt solid #9b9491;
  margin-bottom: 40px;
}

.relatorioModuloV2ContainerModules {
  margin: 0 auto;
}

.relatorioModuloV2Modules {
  vertical-align: middle;
}

.relatorioModuloV2Card {
  background: #fff;
  border-radius: 8px;
  text-align: center;
  padding: 20px;
  min-height: 160px;
  width: 250px;
  margin: 16px;
  display: inline-block;
}

.relatorioModuloV2Card img {
  height: 80px;
  display: block;
  text-align: center;
  margin: 0 auto;
}

.relatorioModuloVHeroImg {
  vertical-align: middle;
  display: inline-block;
}

.relatorioModuloV2CardTextTitulo {
  font-weight: bold;
}

.relatorioModuloV2CardTextDescricao {
  font-size: 12px;
}
