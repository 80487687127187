.PageAgenda_ContainerCardAgenda {
  background: #fff;
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16);
  width: 260px;
  margin-right: 20px;
  display: inline-block;
  vertical-align: top;
}

.PageAgenda_ContainerCardAgendaTitle {
  background: #fe6633;
  color: #fff;
  padding: 10px;
}

.PageAgenda_ContainerCardAgendaBody {
  padding: 10px;
}
