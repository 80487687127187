.componenteMuralFluxoEventos_modalBody {
  overflow-x: hidden;
}

.componenteMuralFluxoEventos_link {
  text-decoration: underline;
  cursor: pointer;
}

.componenteMuralFluxoEventos_link:hover {
  color: #fe6633;
}
