
.componenteModalCotacaoAutoRastreador_FormularioErrosEAlertasContainer {
    text-align: center;
    
}

.componenteModalCotacaoAutoRastreador_FormularioErrosEAlertasMensagem {
    font-size: 25px;
    color: #fe6633;
    font-weight: bold;
    margin-top: 15px;
  }