.carrocelGraficosTitulo {
    font-size: 14px;
}

.carrocelGraficosSubTitulo {
    font-size: 14px;
}

.carrocelGraficoscard {
    height: 320px;
}

.carrocelGraficosBodyGraficos {
    height: 200px;
}