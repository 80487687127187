.pagePagamentoCartao_body {
    width: 100vw;
    margin: 0;
    background: #F6F6F6;
    overflow-x: hidden;
    background: #F6F6F6 url(../../assets/img/buildings-e1602625772132.png) no-repeat;
    background-position: bottom center;
    background-repeat: no-repeat;
    background-size: auto;
    min-height: 100vh;
    box-sizing: border-box;
    font-family: "Montserrat", sans-serif;
    font-size: 1rem;
}

.pagePagamentoCartao_break{
    flex-basis: 100%;
    height: 0;
}

.pagePagamentoCartao_container {
    padding-left: 70px;
    max-width: 1240px;
}


.pagePagamentoCartao_item { flex-grow: 1; }

.pagePagamentoCartao_header{
    flex-grow: 1; 
    padding: 20px;
    background: #fff;  
    border-bottom-color: #eaeaea;
    border-bottom-style: solid;
    border-bottom-width: 1px;
    transition: transform .3s linear, top .3s linear, visibility .3s linear;
}

.pagePagamentoCartao_header-content{
    width: 100%;
    max-width: 1240px;
    margin: 0 auto;
    display: flex;
}

.pagePagamentoCartao_seguranca{
    background: rgba(19, 184, 52, 0.2);
    border: 1px solid #20c233;
    color: #20c233;
    display: inline-block;
    padding: 10px 20px;
    border-radius: 5px;
}

.pagePagamentoCartao_texto{
    margin-left: 2px;
}

.pagePagamentoCartao_text-right{
    text-align: right;
}

.pagePagamentoCartao_section-title {
    line-height: 1;
    font-size: 32px;
    font-weight: 400;
    margin-top: 30px;
}
.pagePagamentoCartao_section-title  span{
    line-height: 1;
    font-size: 32px;
    font-weight: 700;
    margin-top: 30px;
}

.pagePagamentoCartao_content-body{
    width: 100%;
    max-width: 1240px;
    margin: 0 auto;
    display: flex;
}

.pagePagamentoCartao_subtitle-title {
    font-size: 16px;
    font-weight: 400;
    line-height: 1;
    margin-top: 20px;
    margin-bottom: 20px;
}


.pagePagamentoCartao_subtitle-title span {
    font-weight: 700;
}

.pagePagamentoCartao_product-value{
    margin-top: 30px;
    font-size: 18px;
    text-align: right;
}

.pagePagamentoCartao_product-value span{
    color: #FF662D;
    font-weight: 700;
    font-size: 40px;
}

.pagePagamentoCartao_title-step {
    font-size: 35px;
    font-weight: 700;
}

.pagePagamentoCartao_title-section {
    color: #ff662d;
    font-weight: 700;
    text-align: center;
    font-size: 16px;
}

.pagePagamentoCartao_error-section {
    color: #d81818;
    font-weight: 700;
    text-align: center;
    font-size: 16px;
}

@media screen and (max-width: 768px){
    .logo img{
        width: 150px;
    }
    .pagePagamentoCartao_seguranca span{
        display: none;
    }
    .pagePagamentoCartao_product-value{
        text-align: left;
    }
    #root{
        margin-bottom: 50px;
    }
}