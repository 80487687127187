#sidebar {
  overflow: hidden;
  z-index: 3;
  width: 250px;
  -webkit-transition: none;
  transition: none;

  -webkit-animation: animation 1000ms linear both;
  animation: animation 1000ms linear both;
}

#sidebar .active {
  background: #f7f8fa !important;
  border-radius: 20px 0 0 20px !important;
  box-shadow: 0 0 6px rgba(0, 0, 0, 0.16);
}

#sidebar a:hover {
  text-decoration: none;
}

#sidebar .list-group {
  min-width: 250px;
  background-color: #eef0f3;
  min-height: 500vh;
  padding: 20px 0;
}

#sidebar i {
  margin-right: 6px;
}

#sidebar .list-group-item {
  border-radius: 0;
  background-color: #eef0f3;
  color: #666;
  border: none;
  white-space: nowrap;
}

/* highlight active menu */
#sidebar .list-group-item:not(.collapsed) {
  background-color: #eef0f3;
  color: #fe6633;
}

/* open state */
#sidebar .list-group .list-group-item[aria-expanded="true"] {
  background-color: #fff;
}

/* level 1*/
#sidebar .list-group .collapse .list-group-item,
#sidebar .list-group .collapsing .list-group-item {
  padding-left: 20px;
}

/* level 2*/
#sidebar .list-group .collapse > .collapse .list-group-item,
#sidebar .list-group .collapse > .collapsing .list-group-item {
  padding-left: 30px;
}

/* level 3*/
#sidebar .list-group .collapse > .collapse > .collapse .list-group-item {
  padding-left: 40px;
}

#sidebar .collapse {
  visibility: hidden;
}

#sidebar .collapse.show {
  visibility: visible;
  display: block;
}

#sidebar .collapsing {
  -webkit-transition: none;
  transition: none;
  display: none;
}

#sidebar .collapsing.width {
  -webkit-transition-property: width, visibility;
  transition-property: width, visibility;
  width: 0;
  height: auto;
}

@-webkit-keyframes animation {
  0% {
    -webkit-transform: matrix3d(
      1,
      0,
      0,
      0,
      0,
      1,
      0,
      0,
      0,
      0,
      1,
      0,
      -300,
      0,
      0,
      1
    );
    transform: matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, -300, 0, 0, 1);
  }
  5.41% {
    -webkit-transform: matrix3d(
      1,
      0,
      0,
      0,
      0,
      1,
      0,
      0,
      0,
      0,
      1,
      0,
      -170.008,
      0,
      0,
      1
    );
    transform: matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, -170.008, 0, 0, 1);
  }
  10.71% {
    -webkit-transform: matrix3d(
      1,
      0,
      0,
      0,
      0,
      1,
      0,
      0,
      0,
      0,
      1,
      0,
      -90.944,
      0,
      0,
      1
    );
    transform: matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, -90.944, 0, 0, 1);
  }
  16.12% {
    -webkit-transform: matrix3d(
      1,
      0,
      0,
      0,
      0,
      1,
      0,
      0,
      0,
      0,
      1,
      0,
      -44.124,
      0,
      0,
      1
    );
    transform: matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, -44.124, 0, 0, 1);
  }
  21.42% {
    -webkit-transform: matrix3d(
      1,
      0,
      0,
      0,
      0,
      1,
      0,
      0,
      0,
      0,
      1,
      0,
      -19.155,
      0,
      0,
      1
    );
    transform: matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, -19.155, 0, 0, 1);
  }
  32.13% {
    -webkit-transform: matrix3d(
      1,
      0,
      0,
      0,
      0,
      1,
      0,
      0,
      0,
      0,
      1,
      0,
      -0.705,
      0,
      0,
      1
    );
    transform: matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, -0.705, 0, 0, 1);
  }
  42.84% {
    -webkit-transform: matrix3d(
      1,
      0,
      0,
      0,
      0,
      1,
      0,
      0,
      0,
      0,
      1,
      0,
      1.87,
      0,
      0,
      1
    );
    transform: matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 1.87, 0, 0, 1);
  }
  100% {
    -webkit-transform: matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1);
    transform: matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1);
  }
}

@keyframes animation {
  0% {
    -webkit-transform: matrix3d(
      1,
      0,
      0,
      0,
      0,
      1,
      0,
      0,
      0,
      0,
      1,
      0,
      -300,
      0,
      0,
      1
    );
    transform: matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, -300, 0, 0, 1);
  }
  5.41% {
    -webkit-transform: matrix3d(
      1,
      0,
      0,
      0,
      0,
      1,
      0,
      0,
      0,
      0,
      1,
      0,
      -170.008,
      0,
      0,
      1
    );
    transform: matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, -170.008, 0, 0, 1);
  }
  10.71% {
    -webkit-transform: matrix3d(
      1,
      0,
      0,
      0,
      0,
      1,
      0,
      0,
      0,
      0,
      1,
      0,
      -90.944,
      0,
      0,
      1
    );
    transform: matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, -90.944, 0, 0, 1);
  }
  16.12% {
    -webkit-transform: matrix3d(
      1,
      0,
      0,
      0,
      0,
      1,
      0,
      0,
      0,
      0,
      1,
      0,
      -44.124,
      0,
      0,
      1
    );
    transform: matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, -44.124, 0, 0, 1);
  }
  21.42% {
    -webkit-transform: matrix3d(
      1,
      0,
      0,
      0,
      0,
      1,
      0,
      0,
      0,
      0,
      1,
      0,
      -19.155,
      0,
      0,
      1
    );
    transform: matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, -19.155, 0, 0, 1);
  }
  32.13% {
    -webkit-transform: matrix3d(
      1,
      0,
      0,
      0,
      0,
      1,
      0,
      0,
      0,
      0,
      1,
      0,
      -0.705,
      0,
      0,
      1
    );
    transform: matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, -0.705, 0, 0, 1);
  }
  42.84% {
    -webkit-transform: matrix3d(
      1,
      0,
      0,
      0,
      0,
      1,
      0,
      0,
      0,
      0,
      1,
      0,
      1.87,
      0,
      0,
      1
    );
    transform: matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 1.87, 0, 0, 1);
  }
  100% {
    -webkit-transform: matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1);
    transform: matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1);
  }
}

.logoSideBar {
  height: 4vh;
}
