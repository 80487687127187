ul,
li,
label,
tr,
th,
td,
hr {
  margin: 0;
  padding: 0;
  border: 0;
}
html {
  box-sizing: border-box;
}

form legend {
  display: block;
}
ol,
ul {
  list-style: none;
}
blockquote,
q {
  quotes: none;
}
input,
select.form-control::-ms-expand {
  display: none;
}
input::-ms-clear {
  display: none;
}
table {
  border-collapse: collapse;
  border-spacing: 0;
}
img,
video,
svg {
  max-width: 100%;
}
[data-theme] {
  background-color: hsl(0, 0%, 100%);
  background-color: var(--color-bg, #fff);
  color: hsl(240, 17%, 94%);
  color: var(--color-contrast-high, #313135);
}
:root {
  --space-unit: 1em;
  --space-xxxxs: calc(0.125 * var(--space-unit));
  --space-xxxs: calc(0.25 * var(--space-unit));
  --space-xxs: calc(0.375 * var(--space-unit));
  --space-xs: calc(0.5 * var(--space-unit));
  --space-sm: calc(0.75 * var(--space-unit));
  --space-md: calc(1.25 * var(--space-unit));
  --space-lg: calc(2 * var(--space-unit));
  --space-xl: calc(3.25 * var(--space-unit));
  --space-xxl: calc(5.25 * var(--space-unit));
  --space-xxxl: calc(8.5 * var(--space-unit));
  --space-xxxxl: calc(13.75 * var(--space-unit));
  --component-padding: var(--space-md);
}
:root {
  --max-width-xxs: 32rem;
  --max-width-xs: 38rem;
  --max-width-sm: 48rem;
  --max-width-md: 64rem;
  --max-width-lg: 80rem;
  --max-width-xl: 90rem;
  --max-width-xxl: 120rem;
}
.icon--lg {
  font-size: 48px;
  font-size: var(--icon-lg);
}
.icon--xl {
  font-size: 64px;
  font-size: var(--icon-xl);
}
.icon--xxl {
  font-size: 128px;
  font-size: var(--icon-xxl);
}
.icon--is-spinning {
  -webkit-animation: icon-spin 1s infinite linear;
  animation: icon-spin 1s infinite linear;
}
@-webkit-keyframes icon-spin {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
@keyframes icon-spin {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
.icon use {
  color: inherit;
  fill: currentColor;
}

:root {
  --cd-color-1: hsl(220, 4%, 33%);
  --cd-color-1-h: 218;
  --cd-color-1-s: 7%;
  --cd-color-1-l: 32%;
  --font-primary: "Jaldi", sans-serif;
}

.cd-accordion {
  background: hsl(218, 7%, 32%);
  background: var(--cd-color-1);
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  box-shadow: 0 1px 8px rgba(0, 0, 0, 0.1), 0 16px 48px rgba(0, 0, 0, 0.1),
    0 24px 60px rgba(0, 0, 0, 0.1);
  box-shadow: var(--shadow-lg);
}
.form-check-label {
  margin-left: 3px;
}
.cd-accordion--animated .cd-accordion__label::before {
  transition: -webkit-transform 0.3s;
  transition: transform 0.3s;
  transition: transform 0.3s, -webkit-transform 0.3s;
}
.cd-accordion__sub {
  display: none;
  overflow: hidden;
}
.cd-accordion__sub--is-visible {
  display: block;
}
.cd-accordion__item {
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
.mult_Level_check_box {
  margin-top: -0.7rem !important;
}
.cd-accordion__input {
  position: absolute;
  opacity: 0;
}
.cd-accordion__label {
  position: relative;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: center;
  align-items: center;
  padding: 0.75em 1.25em;
  padding: var(--space-sm) var(--space-md);
  background: hsl(218, 7%, 32%);
  background: var(--cd-color-1);
  --color-shadow: lightness(hsl(218, 7%, 32%), 1.2);
  --color-shadow: lightness(var(--cd-color-1), 1.2);
  box-shadow: inset 0 -1px lightness(hsl(218, 7%, 32%), 1.2);
  box-shadow: inset 0 -1px var(--color-shadow);
  color: white;
}
.cd-accordion__label span {
  -ms-flex-order: 3;
  order: 3;
}
.cd-accordion__label:hover {
  background: hsl(218, 7%, 35.2%);
  background: hsl(
    var(--cd-color-1-h),
    var(--cd-color-1-s),
    calc(var(--cd-color-1-l) * 1.1)
  );
}
.cd-accordion__label::after,
.cd-accordion__label--icon-folder::before {
  content: "";
  display: block;
  width: 16px;
  height: 16px;
  background-image: url("../img/cd-icons.svg");
  background-repeat: no-repeat;
  margin-right: 0.25em;
  margin-right: var(--space-xxxs);
}
.cd-accordion__label--icon-folder::before {
  -ms-flex-order: 1;
  order: 1;
}
.cd-accordion__label::after {
  -ms-flex-order: 2;
  order: 2;
}
.cd-accordion__label--icon-folder::before {
  background-position: 0 0;
  -webkit-transform: rotate(-90deg);
  -ms-transform: rotate(-90deg);
  transform: rotate(-90deg);
}
.cd-accordion__label--icon-folder::after {
  background-position: -16px 0;
}
.cd-accordion__label--icon-img::after {
  background-position: -48px 0;
}
.cd-accordion__input:checked + .cd-accordion__label::before {
  -webkit-transform: rotate(0);
  -ms-transform: rotate(0);
  transform: rotate(0);
}
.cd-accordion__input:checked + .cd-accordion__label::after {
  background-position: -32px 0;
}
.cd-accordion__input:checked ~ .cd-accordion__sub {
  display: block;
}
.cd-accordion__sub--l1 .cd-accordion__label {
  background: hsl(218, 7%, 20.8%);
  background: hsl(
    var(--cd-color-1-h),
    var(--cd-color-1-s),
    calc(var(--cd-color-1-l) * 0.65)
  );
  --color-shadow: lightness(hsl(218, 7%, 32%), 0.85);
  --color-shadow: lightness(var(--cd-color-1), 0.85);
  box-shadow: inset 0 -1px lightness(hsl(218, 7%, 32%), 0.85);
  box-shadow: inset 0 -1px var(--color-shadow);
  padding-left: calc(1.25em + 16px);
  padding-left: calc(var(--space-md) + 16px);
}
.cd-accordion__sub--l1 .cd-accordion__label:hover {
  background: hsl(218, 7%, 24%);
  background: hsl(
    var(--cd-color-1-h),
    var(--cd-color-1-s),
    calc(var(--cd-color-1-l) * 0.75)
  );
}
.cd-accordion__item:last-child .cd-accordion__label {
  box-shadow: none;
}
.cd-accordion__sub--l2 .cd-accordion__label {
  padding-left: calc(1.5em + 32px);
  padding-left: calc(var(--space-md) + var(--space-xxxs) + 32px);
}
.cd-accordion__sub--l3 .cd-accordion__label {
  padding-left: calc(1.5em + 48px);
  padding-left: calc(var(--space-md) + var(--space-xxxs) + 48px);
}
.cd-accordion__label--icon-noimg::after {
  background-position: -150px 0;
}
