.photo-thumb {
  border-radius: 100%;
  display: inline-block;
  background: #fff;
  border: 2px solid #fe6633;
}

.navBar {
  height: 40px;
  width: 40px;
  max-width: 40px;
  max-height: 40px;
}

.profile {
  height: 146px;
  width: 146px;
  display: block;
}

.profileSmall {
  height: 5rem;
  width: 5rem;
  max-width: 5rem;
  max-height: 5rem;
}
