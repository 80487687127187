
.componenteModalCotacaoAutoRastreador_formularioFinalizacaoContainer {
    text-align: center;
    
}
.componenteModalCotacaoAutoRastreador_formularioFinalizacaoSucesso {
    font-size: 50px;
    color: #28a745;
    font-weight: bold;
    margin-top: 15vh;
  }

.componenteModalCotacaoAutoRastreador_formularioFinalizacaoMensagem {
    font-size: 25px;
    color: #fe6633;
    font-weight: bold;
    margin-top: 15px;
  }