.componenteAtendimentoHistoricoLead_modalBody {
  min-height: 90%;
  max-height: 90%;
}

.navbarHistorico {
  margin-right: 0.5rem;
  padding-right: 0.1rem;
  width: 12rem;
  font-size: small;
  min-height: 50rem;
}

.profileContainer {
  text-align: center;
  margin-bottom: 0.5rem;
}

.profileContainerNome {
  text-decoration: none;
  font-size: small;
  font-weight: bold;
  text-align: center;
  margin-bottom: 0.5rem;
  word-wrap: break-word;
}

.itemHistorico {
  height: 2rem !important;
  padding-top: 0.3rem !important;
  padding-left: 0.5rem !important;
}

.itemHistoricoIcone {
  font-size: x-small !important;
  color: #fe6633;
  margin-right: 0.5rem;
  padding-bottom: 0.2rem !important;
}

.itemHistorico:hover {
  background-color: #fe6633;
}

.itemHistorico:active {
  background-color: #fe6633;
}

.zebraItem {
  background-color: #fe66335c;
}

.semInfo {
  font-weight: bold;
  background-color: #fe66335c !important;
  border-color: #fe66335c !important;
}
