.pesquisarMotorEventos_containerIcone {
    width: 100%;
  }
.pesquisarMotorEventos_iconesTabela {
  font-size: x-large;
}

.pesquisarMotorEventos_iconesTabelarequest {
    color: #17a2b8;
  }

  
.pesquisarMotorEventos_iconesTabelaresponse {
    color: #17a2b8;
  }

.pesquisarMotorEventos_iconesTabelaerro {
    color: #ffc107;
  }