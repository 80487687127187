#root {
    margin: 0 auto;
}

.pagePagamentoCartao_root {
    margin: 0 auto;
    display: flex;
    justify-content: center;
    align-items: center;
}

#card {
    position: relative;
    width: 22.97rem;
    height: 15.5rem;
    margin-bottom: 3rem;
    margin-right: 100px;
}

@media (max-width: 30rem) {
    #card {
        width: 20.22rem;
        height: 12.75rem;
        margin-bottom: 1.75rem;
    }
}

#card>#card-top,
#card>#card-bottom {
    width: 20.22rem;
    height: 12.75rem;
    border-radius: 0.6rem;
}

#card>#card-top {
    position: absolute;
    content: "";
    top: 0;
    left: 0;
    background: linear-gradient(120deg, #ff662d, #FB5117);
    box-shadow: 1rem 1rem 2.75rem #D7BCC4;
}

#card>#card-top,
#card>#card-top * {
    color: white;
}

#card>#card-top::before {
    position: absolute;
    content: "";
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-size: cover;
    opacity: 0.04;
}

#card>#card-top>.logo {
    position: absolute;
    top: 0.25rem;
    right: 2rem;
}

#card>#card-top>.logo>img {
    margin-top: 20px;
    height: 1rem;
}

#card>#card-top>.card-number {
    position: absolute;
    top: 45%;
    left: 2rem;
    width: 70%;
    opacity: 0.75;
    font-size: 1.25rem;
}

#card>#card-top>.row-container {
    position: absolute;
    bottom: 1rem;
    left: 2rem;
    width: 80%;
    display: flex;
    justify-content: center;
    align-items: center;
    justify-content: space-between;
    opacity: 0.75;
}

#card>#card-top>.row-container>div {
    display: flex;
    flex-direction: column;
    justify-content: start;
    align-items: flex-start;
}

#card>#card-top>.row-container>div>span {
    height: 1rem;
    margin-bottom: 0.5rem;
    padding: 0;
}

#card>#card-bottom {
    position: absolute;
    content: "";
    top: 2.75rem;
    left: 2.75rem;
    background: #fff;
    box-shadow: 0.5rem 0.5rem 2.75rem #ADA9AB;
}

@media (max-width: 30rem) {
    #card>#card-bottom {
        display: none;
    }
}

#card>#card-bottom::after {
    position: absolute;
    content: "";
    top: 2.55rem;
    width: 100%;
    height: 2.55rem;
    background: #E2E0E1;
}

#form {
    width: 27rem;
    display: flex;
    justify-content: center;
    align-items: center;
    align-items: flex-start;
    flex-direction: column;
}

@media (max-width: 30rem) {
    #form {
        width: 20.22rem;
    }
}

#form fieldset {
    border: none;
    margin-top: 1rem;
    margin-left: 0;
    padding-left: 0;
    padding-right: 0;
    width: 100%;
}

#form fieldset input,
#form fieldset select {
    width: 100%;
    padding: 0.5rem 1rem;
    outline: none;
}

#form .row-container {
    display: flex;
    justify-content: center;
    align-items: center;
    justify-content: space-between;
    width: 100%;
}

#form .row-container fieldset {
    flex: 1;
    margin-right: 1.25rem;
}

#form .row-container fieldset:nth-child(3) {
    margin-right: 0;
}

#form .payment-details {
    margin-top: 1rem;
    width: 100%;
}

#form .payment-details>div {
    display: flex;
    justify-content: center;
    align-items: center;
    justify-content: space-between;
}

#form button {
    width: 100%;
    margin-top: 1rem;
    border-style: solid;
    border: 0;
    color: #ffffff;
    border-color: #ff662d;
    background-color: #ff662d;
    border-radius: 2px;
    padding: 15px 40px;
    font-family: inherit;
    font-weight: 500;
    font-size: 16px;
    line-height: 1;
    text-transform: capitalize;
    -webkit-transition: all .3s;
    -o-transition: all .3s;
    transition: all .3s;
}

legend {
    font-weight: 700;
    font-size: 12px;
    margin-bottom: 0;
}

.inputFormField,
select {
    font-size: 12px;
    border: 2px solid rgb(204, 204, 204);
    padding: 9px;
    min-height: 37px;
    background: #fafafa;
    font-weight: 400;
    line-height: 1.5;
    color: #495057;
    border-radius: .25rem;
    transition: border-color .15s ease-in-out, box-shadow .15s ease-in-out;
}

.inputFormField:focus {
    border: 2px solid #FF662D !important;
    border-radius: 3px;
    box-shadow: 0px 0px 5px #FF662D;
}

@media screen and (max-width: 768px) {
    #root {
        margin-bottom: 50px;
    }
    #card {
        display: none;
    }
    #form {
        margin: 30px 20px;
        width: auto;
    }
}