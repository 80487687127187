fieldset {
    margin: 1px;
    border: 1px solid silver;
    padding: 6px;
    border-radius: 4px;
}

legend {
    padding-left: 25px;
    padding-right: 25px;
    margin-bottom: 0;
    width: 20vw;
    color: #fe6633;
}