.componenteModalCotacaoAutoRastreador_cotacaoContainer {
    width: 325vw;
    display: flex;
    white-space: normal;
    overflow-x: hidden;
    background-color: #ffffff;
}

.componenteModalCotacaoAutoRastreador_modalBody {
    height: 80vh;
    max-height: 550px;
    background-color: #ffffff;
}

.modal-header {
    background: #fe6633;
    color: #fff;
}

.modal-header .close {
    color: #fff;
}

.modal-footer {
    justify-content: center;
}