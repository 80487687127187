.componenteAtendimentoAtalhosTelefone_container {
  padding-top: 20px;
  padding-bottom: 20px;
  text-align: center;
}
.componenteAtendimentoAtalhosTelefone_icones {
  font-size: 45px;
  margin-left: 20px;
}
.componenteAtendimentoAtalhosTelefone_icones:hover {
  color: #fe6633;
}
