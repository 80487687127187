.componenteAtendimentoAtalhosTelefone_container {
  padding-top: 20px;
  padding-bottom: 20px;
  text-align: center;
}
.componenteAtendimentoAtalhosTelefone_icone {
}
.componenteAtendimentoAtalhosTelefone_icone:hover {
  color: #fe6633;
}
