html,
body {
  font-family: "Ubuntu", sans-serif;
  background: #f7f8fa;
  color: #707070;
  font-size: 13px;
}

.card {
  padding: 20px;
  border-radius: 0;
}

.textBack {
  color: black;
}

hr {
  margin-top: 1rem !important;
  margin-bottom: 1rem !important;
  border: 0 !important;
  border-top: 1px solid rgba(0, 0, 0, 0.1) !important;
}

.detalheText {
  font-weight: 800;
  font-size: 11px;
  color: #666666;
  display: block;
  width: 100%;
}

label {
  font-weight: bold;
  font-size: 14px;
  color: #666666;
  display: block;
  text-align: left;
  width: 100%;
}

legend {
  font-weight: bold;
  font-size: 14px;
  color: #666666;
  display: block;
  text-align: left;
  width: 100%;
}

.form-control,
.custom-file-label {
  background: #efefef;
  border: none;
}

.form-control {
  background: #efefef;
  border: none;
}

.uppercase {
  text-transform: uppercase;
}

.lowercase {
  text-transform: lowercase;
}

.custom-file-label::after {
  background: #2196f3;
  content: "Selecionar";
  color: #fff;
}

.form-control:focus {
  border: 1px solid #fe6633;
}

.btn-default {
  background: #fe6633;
  color: #fff;
}

.btn-fullWidth {
  width: 100%;
}

.btn-default:hover {
  background: #cc4315;
  color: #fff;
}

.text-orange {
  color: #fe6633;
}

.default-title {
  font-size: 16px;
  font-weight: bold;
}

.btn-secondary {
  background: #cccccc;
  border: 1px solid #ccc;
  color: #666666;
}

.nav-tabs .nav-link.active {
  background: #fe6633;
  color: #fff;
}

.nav-tabs .nav-link {
  border: 1px solid #ccc;
  border-top-left-radius: 0.25rem;
  border-top-right-radius: 0.25rem;
  color: #666;
}

.rating {
  background: #efefef;
  color: #000;
  border-radius: 2px;
}

.svg-rating {
  fill: #000;
  height: 30px;
  width: 30px;
  margin: 0.2rem;
}

.rating-form-2 svg {
  height: 3rem;
  width: 3rem;
  margin: 0.5rem;
}

.rating-form {
  height: 38px;
  text-align: center;
}

.rating-form label {
  position: relative;
  font-weight: bold;
  font-size: 16px;
  color: #666666;
  display: inline-block;
  text-align: left;
  width: auto;
  margin-bottom: 0;
}

.form-control,
input {
  height: 36px;
}

input[type="radio"] {
  position: absolute;
  opacity: 0;
}

input[type="radio"] + svg {
  -webkit-transition: all 0.2s;
  transition: all 0.2s;
}

.rating-form input + svg {
  cursor: pointer;
}

input[class="super-happy"]:hover + svg,
input[class="super-happy"]:checked + svg,
input[class="super-happy"]:focus + svg {
  fill: rgb(0, 109, 217);
}

input[class="happy"]:hover + svg,
input[class="happy"]:checked + svg,
input[class="happy"]:focus + svg {
  fill: rgb(0, 204, 79);
}

input[class="neutral"]:hover + svg,
input[class="neutral"]:checked + svg,
input[class="neutral"]:focus + svg {
  fill: rgb(232, 214, 0);
}

input[class="sad"]:hover + svg,
input[class="sad"]:checked + svg,
input[class="sad"]:focus + svg {
  fill: rgb(229, 132, 0);
}

input[class="super-sad"]:hover + svg,
input[class="super-sad"]:checked + svg,
input[class="super-sad"]:focus + svg {
  fill: rgb(239, 42, 16);
}

.selection-input-4 {
  width: 12px;
  height: 11px;
}

.buttonFormularios {
  margin-top: 31px;
}

.badge {
  width: 100%;
}

.badge-default {
  background: #fe6633;
  color: #fff;
}

.badge-purple {
  background: #9c27b0;
  color: #fff;
}

.badge-whatsapp {
  background: #25d366;
  color: #fff;
}

.badge-sms {
  background: #0d47a1;
  color: #fff;
}

input[type="checkbox"] {
  -ms-transform: scale(1.5);
  -moz-transform: scale(1.5);
  -webkit-transform: scale(1.5);
  -o-transform: scale(1.5);
  padding: 10px;
}

.campoObratorio {
  color: #cc4315;
  font-weight: 800;
  padding-left: 2px;
  font-size: 14px;
}

.campoObratorio2 {
  color: #2196f3;
  font-weight: 800;
  padding-left: 2px;
  font-size: 14px;
}

.customFileInputHide {
  display: none;
}

/* Range style */

/* CHROME */

input[type="range"] {
  -webkit-appearance: none;
}

input[type="range"]::-webkit-slider-runnable-track {
  height: 5px;
  background: #ddd;
  border: none;
  border-radius: 3px;
}

input[type="range"]::-webkit-slider-thumb {
  -webkit-appearance: none;
  border: none;
  height: 16px;
  width: 16px;
  border-radius: 50%;
  background: #fe6633;
  margin-top: -4px;
}

input[type="range"]:focus {
  outline: none;
}

input[type="range"]:focus::-webkit-slider-runnable-track {
  background: #ccc;
}

/* FIREFOX */

input[type="range"] {
  /* fix for FF unable to apply focus style bug  */
  border: 1px solid white;
  /*required for proper track sizing in FF*/
}

input[type="range"]::-moz-range-track {
  height: 5px;
  background: #ddd;
  border: none;
  border-radius: 3px;
}

input[type="range"]::-moz-range-thumb {
  border: none;
  height: 16px;
  width: 16px;
  border-radius: 50%;
  background: goldenrod;
}

/*hide the outline behind the border*/

input[type="range"]:-moz-focusring {
  outline: 1px solid white;
  outline-offset: -1px;
}

input[type="range"]:focus::-moz-range-track {
  background: #ccc;
}

/* INTERNET EXPLORER */

input[type="range"]::-ms-track {
  height: 5px;
  /*remove bg colour from the track, we'll use ms-fill-lower and ms-fill-upper instead */
  background: transparent;
  /*leave room for the larger thumb to overflow with a transparent border */
  border-color: transparent;
  border-width: 6px 0;
  /*remove default tick marks*/
  color: transparent;
}

input[type="range"]::-ms-fill-lower {
  background: #777;
  border-radius: 10px;
}

input[type="range"]::-ms-fill-upper {
  background: #ddd;
  border-radius: 10px;
}

input[type="range"]::-ms-thumb {
  border: none;
  height: 16px;
  width: 16px;
  border-radius: 50%;
  background: goldenrod;
}

input[type="range"]:focus::-ms-fill-lower {
  background: #888;
}

input[type="range"]:focus::-ms-fill-upper {
  background: #ccc;
}

.link_primary {
  text-decoration: underline;
  color: #2196f3;
}

.link_primary:hover {
  text-decoration: underline;
  color: #000000;
}

/* Default radio buttons remain unchanged */

input[type="radio"].defafult-radio {
  opacity: 1 !important;
}
.containerTESTE {
  display: flex;
  align-items: center; /* Vertically center */
}
