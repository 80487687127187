.componentesScore {
  fill: #000;
  height: 30px;
  width: 30px;
  margin: 0.2rem;
}

.scoreMuitoFeliz {
  fill: rgb(0, 109, 217);
}

.scoreFeliz {
  fill: rgb(0, 204, 79);
}

.scoreNeutro {
  fill: rgb(232, 214, 0);
}

.scoreTriste {
  fill: rgb(229, 132, 0);
}

.scoreMuitoTriste {
  fill: rgb(239, 42, 16);
}
