/*----- TIMELINE ITEM -----*/

.timeline-item {
  padding-left: 40px;
  position: relative;
}

.timeline-item:last-child {
  padding-bottom: 0;
}

/*----- TIMELINE INFO -----*/

.timeline-info {
  font-size: 14px;
  margin: 0 0 0.5em 0;
}

/*----- TIMELINE MARKER -----*/

.timeline-marker {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  width: 15px;
}

.timeline-marker:before {
  background: #ff6b6b;
  border: 3px solid transparent;
  border-radius: 100%;
  content: "";
  display: block;
  height: 15px;
  position: absolute;
  top: 4px;
  left: 0;
  width: 15px;
  transition: background 0.3s ease-in-out, border 0.3s ease-in-out;
}

.timeline-marker:after {
  content: "";
  width: 3px;
  background: #ccd5db;
  display: block;
  position: absolute;
  top: 24px;
  bottom: 0;
  left: 6px;
}

.timeline-item:last-child .timeline-marker:after {
  content: none;
}

.timeline-item:not(.period):hover .timeline-marker:before {
  background: transparent;
  border: 3px solid #ff6b6b;
}

/*----- TIMELINE CONTENT -----*/

.timeline-content {
  padding-bottom: 40px;
  word-wrap: break-word;
}

.timeline-content p:last-child {
  margin-bottom: 0;
}

/*----- TIMELINE PERIOD -----*/

.period {
  padding: 0;
}

.period .timeline-info {
  display: none;
}

.period .timeline-marker:before {
  background: transparent;
  content: "";
  width: 15px;
  height: auto;
  border: none;
  border-radius: 0;
  top: 0;
  bottom: 30px;
  position: absolute;
  border-top: 3px solid #ccd5db;
  border-bottom: 3px solid #ccd5db;
}

.period .timeline-marker:after {
  content: "";
  height: 32px;
  top: auto;
}

.period .timeline-content {
  padding: 40px 0 70px;
}

.period {
  margin: 0;
}

.timeline-title {
  margin: 0;
  font-size: 16px;
  font-weight: bold;
}
