.divContainerSpin {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 20rem;
}

.divContainerMessage {
  font-weight: bold;
}
