.hamRotate.active {
  transform: rotate(45deg);
}

.hamRotate180.active {
  transform: rotate(180deg);
}

.line {
  fill: none;
  transition: stroke-dasharray 400ms, stroke-dashoffset 400ms;
  stroke: #fe6633;
  stroke-width: 5.5;
  stroke-linecap: round;
}
.ham {
  cursor: pointer;
  -webkit-tap-highlight-color: transparent;
  transition: transform 400ms;
  -moz-user-select: none;
  -webkit-user-select: none;
  -ms-user-select: none;
  user-select: none;
  height: 40px;
  width: 40px;
}

.ham1 .top {
  stroke-dasharray: 40 139;
}

.ham1 .bottom {
  stroke-dasharray: 40 180;
}

.ham1.active .top {
  stroke-dashoffset: -98px;
}

.ham1.active .bottom {
  stroke-dashoffset: -138px;
}

.itens_navbar {
  display: flex;
}

.title-page {
  font-size: 18px;
  font-weight: bold;
  display: flex;
  margin-top: 7px;
}

.title-page-home {
  font-size: 18px;
  color: #fe6633;
}

.title-page-fav {
  max-width: 20px;
  margin-left: 5px;
}

.fav-no {
  color: #b5b5b552;
}

.fav-yes {
  color: #ffc107;
}

.fav-none {
  display: none;
}

.notification-badge {
  background: #ff0707;
  border: 1px solid #f7f8fa;
  border-radius: 100%;
  font-size: 12px;
  width: 20px;
  height: 20px;
  position: absolute;
  top: 0;
  text-align: center;
  color: #fff;
  right: -5px;
}

.default-title {
  font-size: 16px;
  font-weight: bold;
}

@media (min-width: 768px) {
  #sidebar-wrapper {
    margin-left: 0;
  }

  #page-content-wrapper {
    min-width: 0;
    width: 100%;
  }

  #wrapper.toggled #sidebar-wrapper {
    margin-left: -20rem;
  }
}

.navBarUsuarioNome {
  font-size: 11px;
  font-weight: bold;
  padding-top: 1rem;
  padding-left: 6px;
}

.navBarUsuarioContainer {
  display: flex;
}

.navBarIconeNotificacoes {
  font-size: 20px;
}
