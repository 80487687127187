.react-flow__handle-top {
  top: -8px !important;
  width: 15px !important;
  height: 15px !important ;
  background: green !important ;
}
.react-flow__handle-bottom {
  bottom: -8px !important;
  width: 15px !important;
  height: 15px !important ;
  background: red !important ;
}

.formularioCampanhaJornadaTemplateBody {
  background-color: #EEF0F3;
  width: 70px;
  height: 70px;
  margin-top: 5px;
  text-align: center;
}
.formularioCampanhaJornadaIcone {
  font-size: 40px;
  margin-top: 5px;
}

.formularioCampanhaJornadaText {
  font-size: xx-small;
  font-weight: 800;
}

.formularioCampanhaJornadaTemplateTitle {
  font-size: small;
  font-weight: bold;
}
