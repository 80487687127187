.ComponenteHistoricoAtendimento_ContainerScroll {
  white-space: normal;
}
.componenteHistoricoAtendimento_modalBody {
  overflow-x: hidden;
}

.modal-header {
  background: #fe6633;
  color: #fff;
}

.modal-header .close {
  color: #fff;
}

.modal-footer {
  justify-content: center;
}
